import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { useI18next } from "../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    title,
    subtitle,
    sliderContainer,
    slide,
    card,
    arrows,
    sliderDotsContainer,
    opinionContainer,
    visible,
    hidden,
} from "./opinion-slider.module.scss";
import { config } from "../../../../config";
import { IOpinion } from "../../../../models/opinion.model";
import { ImageDataLike } from "../../../../models/image-data-like";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import useSwiperSettings from "../../../../hooks/home/banner-section/use-swiper-settings";

import SectionWrapper from "../../../molecules/section-wrapper";
import Section from "../../../molecules/section";
import SliderArrows from "../../../molecules/slider/slider-arrows";
import OpinionCard from "../../../molecules/opinion-card";

const { relations } = config;

interface IUseStaticQueryReturn {
    opinion01: ImageDataLike;
}

export default function OpinionSlider() {
    const { t } = useI18next();
    const [titleRef, titleEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.3,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.3,
    });
    const { opinion01 }: IUseStaticQueryReturn = useStaticQuery(query);
    const { arrowsRef, dotsRef, settings } = useSwiperSettings();

    const opinions: IOpinion[] = [
        {
            opinionId: 1,
            media: [
                {
                    remoteImage: opinion01,
                    relations: [relations.mainImage],
                },
            ],
            translations: {
                pl: {
                    content: "„Czuję, że w końcu odnalazłam siebie i swój styl!”",
                    name: "Anna K",
                },
            },
        },
    ];

    const opinionWithSlider = (
        <>
            <Swiper
                {...settings}
                autoplay={{
                    delay: 5000,
                }}
                watchSlidesVisibility={true}
                loop={true}
                slidesPerView={1}
                loopedSlides={3}
                spaceBetween={30}
            >
                {opinions.map((opinion) => (
                    <SwiperSlide key={`slide-${opinion.opinionId}`}>
                        <div className={slide}>
                            <OpinionCard opinion={opinion} className={card} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <SliderArrows ref={arrowsRef} areArrowsSpread={true} className={arrows} />
            <div className={sliderDotsContainer}>
                <div ref={dotsRef} />
            </div>
        </>
    );

    const opinionWithoutSlider = (
        <div className={slide}>
            <OpinionCard opinion={opinions[0]} className={card} />
        </div>
    );

    return (
        <SectionWrapper bgColor="violet-dark" className={opinionContainer}>
            <Section
                ref={titleRef}
                title={{
                    Tag: "h2",
                    children: t("home.opinion.title"),
                    subtitle: t("home.opinion.subtitle"),
                    size: "medium",
                    className: title,
                    subtitleClassName: subtitle,
                    visible: titleEntry?.isIntersecting,
                }}
            />
            <Section
                className={`${sliderContainer} ${contentEntry?.isIntersecting ? visible : hidden}`}
                ref={contentRef}
            >
                {opinions.length > 1 ? opinionWithSlider : opinionWithoutSlider}
            </Section>
        </SectionWrapper>
    );
}

const query = graphql`
    query {
        opinion01: file(relativePath: { eq: "ania_k_opinia.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
    }
`;
