// extracted by mini-css-extract-plugin
export var arrows = "opinion-slider-module--arrows--wnAdM";
export var card = "opinion-slider-module--card--QPOHz";
export var hidden = "opinion-slider-module--hidden--t2BRj";
export var opinionContainer = "opinion-slider-module--opinion-container--q3o2d";
export var slide = "opinion-slider-module--slide--omyaD";
export var sliderContainer = "opinion-slider-module--slider-container--U+gUR";
export var sliderDotsContainer = "opinion-slider-module--slider-dots-container--vRQgv";
export var subtitle = "opinion-slider-module--subtitle--2uVl6";
export var title = "opinion-slider-module--title--XUg9S";
export var visible = "opinion-slider-module--visible--RcOzt";