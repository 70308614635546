import React from "react";
import { getImage } from "gatsby-plugin-image";

import { container, imageBox, ratio } from './instagram-card.module.scss';
import { IInstagramPost } from "../../models/instagram-post.model";

import RatioImage from "../atoms/ratio-image";

interface IInstagramCardProps {
    className?: string,
    post: IInstagramPost,
}

const InstagramCard: React.FC<IInstagramCardProps> = ({
    className = '',
    post,
}) => {
    const { remoteImage, caption, permalink } = post;

    return (
        <a
            className={`${container} ${className}`}
            href={permalink}
            target="_blank"
            rel="noreferrer noopener"
        >
            <RatioImage
                image={getImage(remoteImage)}
                alt={caption}
                className={imageBox}
                ratioClass={ratio}
            />
        </a>
    );
};

export default InstagramCard;
