// extracted by mini-css-extract-plugin
export var card = "how-to-section-module--card--CNa6w";
export var cardContent = "how-to-section-module--card-content--5vX7H";
export var cardTitle = "how-to-section-module--card-title---XnAn";
export var cardTitleWrapper = "how-to-section-module--card-title-wrapper--u4it+";
export var hidden = "how-to-section-module--hidden--yE-Ge";
export var howTo = "how-to-section-module--how-to--au-gL";
export var list = "how-to-section-module--list--iNcfK";
export var list__element = "how-to-section-module--list__element--bedsv";
export var subtitle = "how-to-section-module--subtitle--kfHTq";
export var title = "how-to-section-module--title--+zIA6";
export var visible = "how-to-section-module--visible--w+kFO";