import React, {ReactNode, useState} from 'react';

import ExpandSVG from "../../assets/images/svg/expand-button.svg";
import { responsiveCollapsibleContent, content as contentClass, button, opened, closed } from './responsive-collapsible-content.module.scss';

import Button from "../atoms/button";

export interface ResponsiveCollapsibleContentProps {
    content: string;
    customButton?: ReactNode;
    containerClassName?: string;
    contentClassName?: string;
}

export default function ResponsiveCollapsibleContent(props: ResponsiveCollapsibleContentProps) {
    const { content, customButton, containerClassName = '', contentClassName = '' } = props;
    const [contentVisible, setContentVisible] = useState(false);

    const handleExpand = () => {
        setContentVisible(!contentVisible);
    }

    const visibleClass = contentVisible ? opened : closed;
    const buttonClassName = `${button} ${visibleClass}`;
    const allContentClassNames = `${contentClass} ${contentClassName} ${visibleClass}`;

    return (
        <div className={`${responsiveCollapsibleContent} ${containerClassName}`}>
            <p className={allContentClassNames}>{content}</p>
            {customButton ?
                customButton : (
                    <Button onClick={handleExpand} className={buttonClassName}>
                        <ExpandSVG />
                    </Button>
                )
            }
        </div>
    );
}