import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import getTranslationKey from '../../../../utils/get-translation-key';
import {
    howTo,
    title,
    subtitle,
    list,
    card,
    cardTitle,
    cardContent,
    cardTitleWrapper,
    visible,
    hidden,
    list__element,
} from './how-to-section.module.scss';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';

import SectionWrapper from '../../../molecules/section-wrapper';
import Section from '../../../molecules/section';
import CardTemplate from '../../../molecules/card-template';
import Title from '../../../atoms/title';
import ResponsiveCollapsibleContent from '../../../molecules/responsive-collapsible-content';

type ChecklistType = {
    title: string;
    content: string;
};

export default function HowToSection() {
    const { t, i18n, language } = useI18next();
    const howToTranslations = getTranslationKey('home.howto');

    const [targetRef, entry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.3,
    });

    const checklist: ChecklistType[] = i18n.getResource(
        language,
        'translation',
        howToTranslations('checklist')
    );

    return (
        <SectionWrapper bgColor="blue" className={howTo}>
            <Section
                ref={targetRef}
                title={{
                    children: t(howToTranslations('title')),
                    subtitle: t(howToTranslations('subtitle')),
                    size: 'medium',
                    className: title,
                    subtitleClassName: subtitle,
                    visible: entry?.isIntersecting,
                }}
            >
                <ul className={list}>
                    {checklist.map((item) => (
                        <li
                            key={`howto-checklist-${item.title}`}
                            className={`${list__element} ${
                                entry?.isIntersecting ? visible : hidden
                            }`}
                        >
                            <CardTemplate
                                className={card}
                                title={
                                    <Title
                                        className={cardTitle}
                                        containerClassName={cardTitleWrapper}
                                        visible={entry?.isIntersecting}
                                    >
                                        {item.title}
                                    </Title>
                                }
                                content={
                                    <ResponsiveCollapsibleContent
                                        content={item.content}
                                        containerClassName={`${cardContent} ${
                                            entry?.isIntersecting
                                                ? visible
                                                : hidden
                                        }`}
                                    />
                                }
                            />
                        </li>
                    ))}
                </ul>
            </Section>
        </SectionWrapper>
    );
}
