import React from "react";
import { useI18next } from "../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { title, subtitle, list, item, visible } from './instagram-section.module.scss';
import { IInstagramPost } from "../../../../models/instagram-post.model";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";

import SectionWrapper from "../../../molecules/section-wrapper";
import Section from "../../../molecules/section";
import InstagramCard from "../../../molecules/instagram-card";

interface IInstagramSectionProps {
    className?: string,
    posts: IInstagramPost[],
}

const InstagramSection: React.FC<IInstagramSectionProps> = ({
    className = '',
    posts,
}) => {
    const { t } = useI18next();
    const [targetRef, entry] = useIntersectionObserver<HTMLDivElement>({ threshold: 0.3 });

    return (
        <SectionWrapper className={className} bgColor="beige-light">
            <Section
                ref={targetRef}
                title={{
                    children: t('home.instagram.title'),
                    subtitle: t('home.instagram.subtitle'),
                    Tag: 'h2',
                    align: 'center',
                    subtitleClassName: subtitle,
                    className: title,
                    size: 'medium',
                }}
            >
                <ul className={list}>
                    {posts.map((post, index) => {
                        return (
                            <li
                                key={`instagram-card-item-${index}`}
                                className={`${item} ${entry?.isIntersecting ? visible : ''}`}
                            >
                                <InstagramCard post={post} />
                            </li>
                        );
                    })}
                </ul>
            </Section>
        </SectionWrapper>
    );
};

export default InstagramSection;