import React, { useImperativeHandle, useRef } from "react";

import { sliderArrows, button, left, separator, spread } from "./slider-arrows.module.scss";
import Arrow from "../../../assets/images/svg/arrow.svg";

import Button from "../../atoms/button";

export interface ISliderArrows {
    onPrev?: () => void;
    onNext?: () => void;
    className?: string;
    areArrowsSpread?: boolean;
}

export interface ISliderArrowsRef {
    prev: HTMLButtonElement | null;
    next: HTMLButtonElement | null;
}

const SliderArrows = React.forwardRef<ISliderArrowsRef, ISliderArrows>(
    ({ onNext, onPrev, className, areArrowsSpread = false }, ref) => {
        const prevRef = useRef<HTMLButtonElement>(null);
        const nextRef = useRef<HTMLButtonElement>(null);

        useImperativeHandle(ref, () => ({
            get prev() {
                return prevRef.current;
            },
            get next() {
                return nextRef.current;
            },
        }));

        return (
            <div className={`${sliderArrows} ${areArrowsSpread ? spread : ""} ${className}`}>
                <Button className={`${button} slider-arrow-prev`} onClick={onPrev} ref={prevRef}>
                    <Arrow className={left} />
                </Button>
                {!areArrowsSpread && <div className={separator} />}
                <Button className={`${button} slider-arrow-next`} onClick={onNext} ref={nextRef}>
                    <Arrow />
                </Button>
            </div>
        );
    }
);

export default SliderArrows;
