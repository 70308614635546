// extracted by mini-css-extract-plugin
export var about = "about-section-module--about--o7Fya";
export var backgroundImage = "about-section-module--background-image--nBhK6";
export var button = "about-section-module--button--M0qHo";
export var content = "about-section-module--content--jst8Q";
export var contentWrapper = "about-section-module--content-wrapper--8aD6W";
export var desktop = "about-section-module--desktop--g1LgO";
export var hidden = "about-section-module--hidden--9wH84";
export var mobile = "about-section-module--mobile--pRhDC";
export var subtitle = "about-section-module--subtitle--ll2lU";
export var title = "about-section-module--title--QRoPu";
export var titleContainer = "about-section-module--title-container--kXR5d";
export var topPadding = "about-section-module--top-padding--La7Tk";
export var visible = "about-section-module--visible--uU6mq";