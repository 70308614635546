import React, { ReactNode } from 'react';
import * as CSS from 'csstype';

import { cardTemplate } from './card-template.module.scss';

export interface CardTemplateProps {
    title: ReactNode;
    content?: ReactNode;
    actions?: ReactNode;
    className?: string;
    style?: CSS.Properties;
}

const CardTemplate = React.forwardRef<HTMLDivElement, CardTemplateProps>(
    (props, ref) => {
        const { title, content, actions, className, style } = props;

        return (
            <div
                className={`${cardTemplate} ${className}`}
                ref={ref}
                style={style}
            >
                {title}
                {content}
                {actions}
            </div>
        );
    }
);

CardTemplate.displayName = 'CardTemplate';

export default CardTemplate;
