// extracted by mini-css-extract-plugin
export var img = "subsections-advices-module--img--85KXo";
export var quote = "subsections-advices-module--quote--4Asaz";
export var quoteHeading = "subsections-advices-module--quote-heading--EuV6u";
export var quoteText = "subsections-advices-module--quote-text--KAydI";
export var sectionsImg = "subsections-advices-module--sections-img--LCgNM";
export var sub1 = "subsections-advices-module--sub1--XEMx-";
export var sub2 = "subsections-advices-module--sub2--y9UhF";
export var sub3 = "subsections-advices-module--sub3--nPbrr";
export var subButton = "subsections-advices-module--sub-button--LQi3D";
export var subContent = "subsections-advices-module--sub-content--f6wul";
export var subHeading = "subsections-advices-module--sub-heading--wNyQN";
export var subWrapper = "subsections-advices-module--sub-wrapper--0WKk4";
export var subsContainer = "subsections-advices-module--subs-container--w3c6v";