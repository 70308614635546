import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    about,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
} from './about-section.module.scss';
import AboutBackground from '../../../../assets/images/svg/modatoty.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';

export interface AboutSectionProps {
    withTopPadding?: boolean;
}

export default function AboutSection({ withTopPadding }: AboutSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'about');

    return (
        <SectionWrapper
            bgColor={'beige-light'}
            className={`${about} ${withTopPadding ? topPadding : ''}`}
        >
            <Section
                ref={backgroundRef}
                className={`${backgroundImage} ${
                    backgroundEntry?.isIntersecting ? visible : hidden
                }`}
            >
                <AboutBackground className={desktop} />
                <AboutBackgroundMobile className={mobile} />
            </Section>
            <Section
                ref={contentRef}
                column={'narrow'}
                className={`${contentWrapper} ${
                    contentEntry?.isIntersecting ? visible : hidden
                }`}
                title={{
                    size: 'medium',
                    subtitle: t(aboutTranslations('title')),
                    containerClassName: titleContainer,
                    className: title,
                    subtitleClassName: subtitle,
                    children: t(aboutTranslations('subtitle')),
                    align: 'center',
                    visible: contentEntry?.isIntersecting,
                }}
            >
                <p className={content}>{t(aboutTranslations('content'))}</p>
                <Link className={button} to="/o-nas">
                    {t(aboutTranslations('button'))}
                </Link>
            </Section>
        </SectionWrapper>
    );
}
