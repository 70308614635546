import React from "react";

import { container, avatar, contentBox, text, signature } from "./opinion-card.module.scss";
import { config } from "../../config";
import { IOpinion } from "../../models/opinion.model";
import { useTranslation } from "../../hooks/use-translation";
import useMedia from "../../hooks/use-media";

import Avatar from "../atoms/avatar";

const { translationKeys, relations } = config;

interface IOpinionCardProps {
    className?: string;
    opinion: IOpinion;
}

const OpinionCard: React.FC<IOpinionCardProps> = ({ className = "", opinion }) => {
    const { media } = opinion;
    const { content, name } = useTranslation(opinion, translationKeys.opinion);
    const mediaItem = useMedia({ media, relation: relations.mainImage });
    return (
        <div className={`${container} ${className}`}>
            <Avatar image={mediaItem.image} alt={mediaItem.alt} className={avatar} />
            <div className={contentBox}>
                <p className={text}>{content}</p>
                <p className={signature}>{name}</p>
            </div>
        </div>
    );
};

export default OpinionCard;
