import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import {
    subsContainer,
    quote,
    quoteHeading,
    quoteText,
    img,
    subWrapper,
    subHeading,
    subContent,
    sectionsImg,
    sub1,
    sub2,
    sub3,
    subButton,
} from "./subsections-advices.module.scss";

import { IPageBase } from "../../../../models/page-base.model";

import SectionWrapper from "../../../molecules/section-wrapper";
import Section from "../../../molecules/section";
import Link from "../../../atoms/link";
import Title from "../../../atoms/title";

interface IAdvicesProps extends IPageBase {
    readonly data: { [key: string]: ImageDataLike };
}

const SubsectionsAdvices: React.FC<IAdvicesProps> = ({ data }) => {
    const { coverImg, figures, mainImg } = data;
    const measureImage = getImage(coverImg);
    const figureImage = getImage(figures);
    const beautiesImage = getImage(mainImg);

    return (
        <>
            <SectionWrapper className={subsContainer}>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={quote}
                >
                    <div className={quoteHeading}>"Właściwy strój to przejaw dobrych manier"</div>
                    <div className={quoteText}>Tom Ford</div>
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"full"}
                    className={`${img} ${sub1}`}
                >
                    {measureImage && (
                        <GatsbyImage className={sectionsImg} image={measureImage} alt={""} />
                    )}
                    <div className={subWrapper}>
                        <Title size={"medium"} align={"center"} className={subHeading}>
                            Jak prawidłowo się mierzyć?
                        </Title>
                        <div className={subContent}>
                            Często oznaczenie samego rozmiaru nie wystarczy.
                        </div>
                        <Link className={subButton} to="/jak-sie-mierzyc/">
                            Dowiedz się więcej
                        </Link>
                    </div>
                </Section>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"full"}
                    className={`${img} ${sub2}`}
                >
                    {figureImage && (
                        <GatsbyImage className={sectionsImg} image={figureImage} alt={""} />
                    )}
                    <div className={subWrapper}>
                        <Title size={"medium"} align={"center"} className={subHeading}>
                            Określ figurę i dowiedz się co ci pasuje.
                        </Title>
                        <div className={subContent}>Określ figurę i dowiedz się co ci pasuje.</div>
                        <Link className={subButton} to="/okresl-figure/">
                            Dowiedz się więcej
                        </Link>
                    </div>
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"full"}
                    className={`${img} ${sub3}`}
                >
                    {beautiesImage && (
                        <GatsbyImage className={sectionsImg} image={beautiesImage} alt={""} />
                    )}
                    <div className={subWrapper}>
                        <Title size={"medium"} align={"center"} className={subHeading}>
                            Znajdź najlepszy kolor dla siebie.
                        </Title>
                        <div className={subContent}>
                            Nasza intuicja w kwestii, intensywności kolorów idzie w parze z
                            intensywnością urody lub temperamentu. Zazwyczaj kobiety o jasnej cerze
                            i delikatnych rysach, wybierają barwy jasne, natomiast te o oliwkowej
                            cerze, ostrzejszych rysach i ciemniejszych włosach lepiej czują się w
                            kolorach nasyconych.
                        </div>
                        <Link className={subButton} to="/typ-urody/">
                            Dowiedz się więcej
                        </Link>
                    </div>
                </Section>
            </SectionWrapper>
        </>
    );
};

export default SubsectionsAdvices;
