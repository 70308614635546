import React from "react";

import { useI18next } from "../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import getTranslationKey, { commonTranslations } from "../../../../utils/get-translation-key";
import WomanPortrait from "../../../../assets/images/svg/woman-portrait.svg";
import CheckSVG from "../../../../assets/images/svg/check.svg";
import {
    benefits,
    cardSection,
    checklistSection,
    buttonSection,
    visible,
    hidden,
    checklist as checklistClass,
    checklist__element,
} from "./benefits-section.module.scss";

import SectionWrapper from "../../../molecules/section-wrapper";
import Section from "../../../molecules/section";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import SimpleCard from "../../../molecules/simple-card";
import Link from "../../../atoms/link";

type CardType = {
    title: string;
    subtitle: string;
    content: string;
};

type ChecklistType = { content: string };

export default function BenefitsSection() {
    const { t, i18n, language } = useI18next();
    const [cardsRef, cardsEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.3,
    });
    const [checklistRef, checklistEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.3,
    });
    const [buttonRef, buttonEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.3,
    });
    const benefitsTranslations = getTranslationKey("home", "benefits");

    const cardsTranslations: CardType[] = i18n.getResource(
        language,
        "translation",
        benefitsTranslations("cards")
    );
    const checklist: ChecklistType[] = i18n.getResource(
        language,
        "translation",
        benefitsTranslations("checklist")
    );

    return (
        <SectionWrapper bgColor="white" className={benefits}>
            <Section
                className={`${cardSection} ${cardsEntry?.isIntersecting ? visible : hidden}`}
                ref={cardsRef}
            >
                <WomanPortrait />
                {cardsTranslations.map((card) => (
                    <SimpleCard
                        key={`${benefitsTranslations("card")}-${card.subtitle}`}
                        title={card.title}
                        subtitle={card.subtitle}
                        content={card.content}
                        visible={cardsEntry?.isIntersecting}
                    />
                ))}
            </Section>
            <Section
                className={`${checklistSection} ${
                    checklistEntry?.isIntersecting ? visible : hidden
                }`}
                ref={checklistRef}
            >
                <ul className={checklistClass}>
                    {checklist.map((item) => (
                        <li
                            key={`benefits-checklist-${item.content}`}
                            className={checklist__element}
                        >
                            <CheckSVG />
                            {item.content}
                        </li>
                    ))}
                </ul>
            </Section>
            <Section
                column="narrow"
                className={`${buttonSection} ${buttonEntry?.isIntersecting ? visible : hidden}`}
                ref={buttonRef}
            >
                <Link color="primary" type="filled" to={"wystylizuj-mnie"} textUppercase>
                    {t(commonTranslations("stylize"))}
                </Link>
            </Section>
        </SectionWrapper>
    );
}
