import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
    banner as bannerClass,
    backgroundImage as backgroundImageClass,
    elementImage,
    elements,
    card,
    gatsbyImgWrapper,
    visible,
    hidden,
} from './banner.module.scss';
import { IBanner } from '../../models/banner.model';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';
import { useMediaTranslation } from '../../hooks/use-media-translation';

import BannerCard from '../molecules/banner-card';
import useMediaQuery from '../../hooks/use-media-query';
import useMedia from '../../hooks/use-media';

const { translationKeys, relations } = config;

export interface BannerProps {
    banner: IBanner;
    parallaxSize?: string;
    isIntersecting?: boolean;
}

export default function Banner({
    banner,
    parallaxSize,
    isIntersecting,
}: BannerProps) {
    const { title, description, buttonText, buttonUrl } = useTranslation(
        banner,
        translationKeys.banner
    );
    const media = useMediaTranslation(banner);
    const isTablet = useMediaQuery(832); //832px - tablet
    const [backgroundImage, backgroundAlt] = useMedia({
        media,
        relation: relations.mainImage,
    });
    const [mobileImage, mobileAlt] = useMedia({
        media,
        relation: relations.mainImageMobile,
    });

    return (
        <div className={bannerClass}>
            {backgroundImage && mobileImage && (
                <GatsbyImage
                    className={backgroundImageClass}
                    alt={backgroundAlt}
                    image={isTablet ? mobileImage : backgroundImage}
                    objectFit="cover"
                />
            )}
            <div
                className={`${elements} ${isIntersecting ? visible : hidden}`}
                data-swiper-parallax={parallaxSize ? parallaxSize : undefined}
            >
                <BannerCard
                    className={card}
                    title={title}
                    content={description}
                    buttonText={buttonText}
                    buttonUrl={buttonUrl}
                    visible={isIntersecting}
                />
                <div className={elementImage}>
                    {mobileImage && (
                        <GatsbyImage
                            alt={mobileAlt}
                            className={gatsbyImgWrapper}
                            objectFit="contain"
                            image={mobileImage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
