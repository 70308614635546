import React, { useCallback, useRef } from 'react';
import {
    Autoplay,
    Navigation,
    Pagination,
    Parallax,
    Swiper as SwiperType,
} from 'swiper';

import {
    sliderDot,
    active,
} from '../../../components/atoms/slider/slider-dot.module.scss';
import { button } from '../../../components/atoms/button.module.scss';
import { NavigationOptions } from 'swiper/types';
import { ISliderArrowsRef } from '../../../components/molecules/slider/slider-arrows';

const isNavigationOptions = (value: any): value is NavigationOptions => {
    if (typeof value === 'object') {
        return true;
    } else {
        return false;
    }
};

export const swiperPlugins = [Parallax, Autoplay, Navigation, Pagination];

export default function useSwiperSettings() {
    const parallaxSize = '-600';
    const arrowsRef = useRef<ISliderArrowsRef>(null);
    const dotsRef = useRef<HTMLDivElement>(null);

    //poniższe rozwiazanie ze względu na:
    //https://github.com/nolimits4web/swiper/issues/3855
    const handleSwiperInit = useCallback((swiper: SwiperType) => {
        if (
            swiper.params.navigation &&
            isNavigationOptions(swiper.params.navigation)
        ) {
            swiper.params.navigation.nextEl = arrowsRef.current?.next;
            swiper.params.navigation.prevEl = arrowsRef.current?.prev;
            swiper.navigation.init();
            swiper.navigation.update();
        }
    }, []);

    const settings = {
        parallax: true,
        loop: true,
        speed: 2000,
        onInit: handleSwiperInit,
        longSwipesRatio: 0.2,
        autoplay: {
            delay: 7000,
            disableOnInteraction: true,
        },
        navigation: {
            nextEl: arrowsRef?.current?.next,
            prevEl: arrowsRef?.current?.prev,
        },
        pagination: {
            el: dotsRef.current,
            bulletClass: `${button} ${sliderDot}`,
            bulletActiveClass: active,
            clickable: true,
            bulletElement: 'button',
        },
    };

    return { arrowsRef, dotsRef, settings, parallaxSize };
}
