import React, { useRef } from "react";
import * as CSS from "csstype";

import CardTemplate from "./card-template";
import {
    bannerCard,
    title as titleClass,
    content as contentClass,
    buttonSection,
    button,
    visible as visibleClass,
    hidden,
} from "./banner-card.module.scss";

import Title from "../atoms/title";
import { IBannerTranslation } from "../../models/banner.model";
import Link from "../atoms/link";
import useCheckIfInternal from "../../hooks/use-check-if-internal";
import useFontSizeFit from "../../hooks/use-font-size-fit";

export type BannerCardProps = {
    title: string;
    content?: string;
    buttonText: IBannerTranslation["buttonText"];
    buttonUrl: IBannerTranslation["buttonUrl"];
    className?: string;
    style?: CSS.Properties;
    visible?: boolean;
};

const BannerCard = React.forwardRef<HTMLDivElement, BannerCardProps>((props, ref) => {
    const { title, content, buttonText, buttonUrl, className, style, visible } = props;

    const contentParagraphs = content?.split("\n");
    const isButtonUrlInternal = useCheckIfInternal(buttonUrl);

    const titleContainerRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadElement>(null);

    const fontSize = useFontSizeFit(titleRef, titleContainerRef);
    const titleStyle = fontSize ? { fontSize: `${fontSize}px` } : undefined;

    return (
        <CardTemplate
            ref={ref}
            title={
                <Title
                    titleStyle={titleStyle}
                    className={titleClass}
                    ref={titleRef}
                    containerRef={titleContainerRef}
                >
                    {title}
                </Title>
            }
            content={
                contentParagraphs && (
                    <div className={contentClass}>
                        {contentParagraphs.map((item) => (
                            <p key={`banner-card-p-${item}`}>{item}</p>
                        ))}
                    </div>
                )
            }
            actions={
                buttonUrl &&
                buttonText && (
                    <div className={buttonSection}>
                        <Link
                            to={buttonUrl}
                            color="primary"
                            type="filled"
                            external={!isButtonUrlInternal}
                            className={button}
                        >
                            {buttonText}
                        </Link>
                    </div>
                )
            }
            className={`${bannerCard} ${className} ${visible ? visibleClass : hidden}`}
            style={style}
        />
    );
});

BannerCard.displayName = "BannerCard";

export default BannerCard;
