import React from 'react';

import {
    simpleCard,
    title as titleClass,
    content as contentClass,
    visible as visibleClass,
    hidden,
} from './simple-card.module.scss';

import Title from '../atoms/title';
import CardTemplate from './card-template';

export interface SimpleCardProps {
    title?: string;
    subtitle?: string;
    content?: string;
    className?: string;
    titleClassName?: string;
    contentClassName?: string;
    visible?: boolean;
}

export default function SimpleCard({
    title,
    subtitle,
    content,
    className = '',
    visible = true,
}: SimpleCardProps) {
    const titleFragments = title?.split('\n');

    return (
        <CardTemplate
            className={`${simpleCard} ${className}`}
            title={
                <Title
                    subtitle={subtitle}
                    size="small"
                    className={titleClass}
                    visible={visible}
                >
                    {titleFragments?.map((item, index) => (
                        <React.Fragment key={`title-fragment-${item}`}>
                            {item}
                            {index + 1 !== titleFragments.length ? (
                                <br />
                            ) : null}
                        </React.Fragment>
                    ))}
                </Title>
            }
            content={
                <p
                    className={`${contentClass} ${
                        visible ? visibleClass : hidden
                    }`}
                >
                    {content}
                </p>
            }
        />
    );
}
