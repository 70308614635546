import React, { useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';

import 'swiper/swiper.scss';
import {
    bannerSection,
    section,
    sliderArrows,
    sliderDotsContainer,
    sliderDots,
} from './banner-section.module.scss';
import { slickWrapper } from './slider.module.scss';
import { IBanner } from '../../../models/banner.model';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import useSwiperSettings, {
    swiperPlugins,
} from '../../../hooks/home/banner-section/use-swiper-settings';

import Section from '../../molecules/section';
import Banner from '../Banner';
import SectionWrapper from '../../molecules/section-wrapper';
import SliderArrows from '../../molecules/slider/slider-arrows';

SwiperCore.use(swiperPlugins);

export interface IBannerSection {
    banners: IBanner[];
}

const BannerSection: React.FC<IBannerSection> = ({ banners }) => {
    const [sectionRef, sectionEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const { arrowsRef, dotsRef, settings, parallaxSize } = useSwiperSettings();

    const bannerComponent = useCallback(
        (banner: IBanner) => (
            <Banner
                banner={banner}
                parallaxSize={parallaxSize}
                isIntersecting={sectionEntry?.isIntersecting}
            />
        ),
        [sectionEntry]
    );

    const bannersWithSlider = (
        <>
            <div className={sliderDotsContainer}>
                <div className={sliderDots} ref={dotsRef} />
            </div>
            <Section column="full" className={section} ref={sectionRef}>
                <Swiper className={slickWrapper} {...settings}>
                    {banners.map((banner) => (
                        <SwiperSlide key={`banner-${banner?.bannerId}`}>
                            {bannerComponent(banner)}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Section>
            <SliderArrows ref={arrowsRef} className={sliderArrows} />
        </>
    );

    const bannersWithoutSlider = (
        <Section column="full" className={section} ref={sectionRef}>
            {bannerComponent(banners[0])}
        </Section>
    );

    return (
        <SectionWrapper bgColor="beige-light" className={bannerSection}>
            {banners.length > 1 ? bannersWithSlider : bannersWithoutSlider}
        </SectionWrapper>
    );
};

export default BannerSection;
